import { ButtonHTMLAttributes, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  AnalyticsService,
  Events,
  useAnalytics,
} from "services/analytics.service";

export enum HLButtonSize {
  Small = "small",
  Medium = "medium",
}

export enum HLButtonVariant {
  Default = "default",
  Danger = "danger",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

enum ButtonStates {
  "DEFAULT",
  "MOUSELEAVE",
  "MOUSEENTER",
  "MOUSEDOWN",
  "MOUSEUP",
}

export interface HLButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  buttonAnalyticsId: string; // used to identify which button was pressed for analytics service
  buttonAnalyticsPayload?: { [key: string]: unknown };
  onClick: () => void;
  size?: HLButtonSize;
  variant?: HLButtonVariant;

  /** Should be `true` for low-emphasis buttons where the background is Spruce-15, `false` otherwise */
  highBackgroundContrast?: boolean;

  highEmphasis?: boolean;
  disabled?: boolean;
  ariaAttributes?: { [key: string]: string };
}

const HLButton = ({
  title,
  buttonAnalyticsId,
  buttonAnalyticsPayload,
  size = HLButtonSize.Medium,
  variant,
  highEmphasis,
  highBackgroundContrast,
  onClick,
  disabled,
  ariaAttributes,
}: HLButtonProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonState, setButtonState] = useState<ButtonStates>(
    ButtonStates.DEFAULT
  );

  const buttonMotionVariants = {
    default: {
      opacity: 1,
      transition: {
        duration: 0,
      },
    },
    mouseEnter: {
      opacity: 0.8,
      transition: {
        duration: 0,
      },
    },
    mouseLeave: {
      opacity: 1,
      transition: {
        duration: 0.15,
      },
    },
    buttonInactive: {
      opacity: 0.8,
      transition: {
        duration: 0.15,
      },
    },
    buttonActive: {
      opacity: 0.3,
      transition: {
        duration: 0,
      },
    },
  };
  const analytics = useAnalytics() as AnalyticsService;

  const buttonVariantStyle = () => {
    switch (variant) {
      case HLButtonVariant.Danger:
        return highEmphasis
          ? "bg-red-80 text-red-40"
          : "bg-spruce-15 text-red-60";
      case HLButtonVariant.Secondary:
        return highEmphasis
          ? "bg-yellow-80 text-yellow-70"
          : "bg-yellow-93 text-yellow-70";
      case HLButtonVariant.Tertiary:
        return "bg-transparent text-yellow-70";
      default:
        return highEmphasis
          ? "bg-gradient-to-b from-yellow-60 to-yellow-50 text-yellow-15"
          : `text-yellow-70 ${
              highBackgroundContrast ? "bg-spruce-20" : "bg-spruce-15"
            }`;
    }
  };

  const getMotionVariant = () => {
    switch (buttonState) {
      case ButtonStates.MOUSEENTER:
        return "mouseEnter";
      case ButtonStates.MOUSEDOWN:
        return "buttonActive";
      case ButtonStates.MOUSEUP:
        return "buttonInactive";
      case ButtonStates.MOUSELEAVE:
        return "mouseLeave";
      default:
        return "default";
    }
  };

  const handleClick = () => {
    analytics.track(Events.click, {
      ...buttonAnalyticsPayload,
      buttonAnalyticsId,
    });

    onClick();
  };

  return (
    <motion.button
      ref={buttonRef}
      onClick={handleClick}
      disabled={disabled}
      className={`border-0 flex-1 items-center justify-center w-full rounded-lg overflow-hidden text-sm text-yellow-70 font-semibold cursor-pointer ${
        disabled ? "!opacity-40" : ""
      } ${
        size === HLButtonSize.Medium ? "h-12 max-h-12" : "h-10 max-h-10"
      } ${buttonVariantStyle()}`}
      variants={buttonMotionVariants}
      animate={getMotionVariant()}
      onMouseEnter={() => setButtonState(ButtonStates.MOUSEENTER)}
      onMouseLeave={() => {
        setButtonState(ButtonStates.MOUSELEAVE);
      }}
      onMouseDown={() => setButtonState(ButtonStates.MOUSEDOWN)}
      onTouchStart={() => setButtonState(ButtonStates.MOUSEDOWN)}
      onMouseUp={() => setButtonState(ButtonStates.MOUSEUP)}
      onTouchEnd={() => setButtonState(ButtonStates.MOUSEUP)}
      {...ariaAttributes}
    >
      {title}
    </motion.button>
  );
};

export default HLButton;
